#totop {
    position: fixed;
    bottom: 40px;
    right: 40px;
    z-index: 99;
    opacity: 0;
    pointer-events: none;
    transition: ease-in-out 400ms;
    
	.action {
		background-color: $white;
		color: $gray-800;
		display: block;
		height: 40px;
		text-align: center;
		width: 40px;
		line-height: 40px;
		border-radius: 50%;
		opacity: 0.4;
		box-shadow: 0px 0px 8px $gray-800;
        cursor: pointer;
        
        .fa {
            line-height: 38px;
        }
	}
	&.show {
		opacity: 1;
		pointer-events: auto;
	}
}