/* Animation */
@mixin animation($animate...) {
    $max: length($animate);
    $animations: '';

    @for $i from 1 through $max {
        $animations: #{$animations + nth($animate, $i)};

        @if $i < $max {
            $animations: #{$animations + ", "};
        }
    }
    -webkit-animation: $animations;
    -moz-animation:    $animations;
    -o-animation:      $animations;
    animation:         $animations;
}

/* Keyframes */
@mixin keyframes($animationName) {
    @-webkit-keyframes #{$animationName} {
        @content;
    }
    @-moz-keyframes #{$animationName} {
        @content;
    }
    @-o-keyframes #{$animationName} {
        @content;
    }
    @keyframes #{$animationName} {
        @content;
    }
}

@include keyframes(bounceIn) {
    0% {
        opacity: 0;
        transform: scale(0.3) translate3d(0, 0, 0);
    }
    50% {
        opacity: 0.9;
        transform: scale(1.1);
    }
    80% {
        opacity: 1;
        transform: scale(0.89);
    }
    100% {
        opacity: 1;
        transform: scale(1) translate3d(0, 0, 0);
    }
}
@include keyframes(bounceOut) {
    100% {
        opacity: 0;
        transform: scale(0.3) translate3d(0, 0, 0);
    }
    80% {
        opacity: 0.9;
        transform: scale(1.1);
    }
    50% {
        opacity: 1;
        transform: scale(0.89);
    }
    0% {
        opacity: 1;
        transform: scale(1) translate3d(0, 0, 0);
    }
}
@include keyframes(fadeIn) {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 0.9;
    }
    80% {
        opacity: 1;
    }
    100% {
        opacity: 1;
    }
}
@include keyframes(fadeOut) {
    100% {
        opacity: 0;
    }
    80% {
        opacity: 0.9;
    }
    50% {
        opacity: 1;
    }
    0% {
        opacity: 1;
    }
}