#modal {
	.app-modal {
		.wrapper {
			position: fixed;
			z-index: 10000;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			display: inline-block;
			.modal-box {
				box-shadow: 0px 0px 70px 3px rgba(0,0,0,0.51);
				box-sizing: border-box;
				min-height: 400px;
				background-color: #fff;
				padding: 30px;
				max-width: 80vw;
				position: relative;
				background-size: cover;
				text-align: center;
				width: 400px;
				.icon {
        			z-index: 1;
					i {
						font-size: 70px;
						@include animation('bounceIn 0.75s');
						&.fa-check:before {
							color: theme-color("success");
						}
					}
				}
				.title {
					z-index: 1;
					margin-bottom: 10px;
				}
				.modal-body {
					margin-top: 10px;
        			z-index: 1;
					overflow: auto;
				}
				.thumb {
					margin-top: 10px;
        			z-index: 1;
					img {
						width: 90px;
        				height: auto;
					}
				}
				.image {
					margin-top: 10px;
        			z-index: 1;
					.slideshow-wrapper {
						.slideshow-prev {
							position: absolute;
							top: 50%;
							left: -50px;
							transform: translateY(-50%);
							background-size: 100%;
							background-color: rgba(0, 0, 0, 0.2);
							&:hover {
								background-color: rgba(0, 0, 0, 0.4);
							}
							height: 40px;
							width: 40px;
							color: $white;
							border: none;
							cursor: pointer;
							outline: none;
							transition: all 0.2s linear;
							i {
								font-size: 18px;
								&:before {
									vertical-align: top;
								}
							}
						}
						.slideshow-next {
							position: absolute;
							top: 50%;
							left: 50px;
							transform: translateY(-50%);
							background-size: 100%;
							background-color: rgba(0, 0, 0, 0.2);
							height: 40px;
							width: 40px;
							color: $white;
							border: none;
							cursor: pointer;
							outline: none;
							transition: all 0.2s linear;
							i {
								font-size: 18px;
								&:before {
									vertical-align: top;
								}
							}
						}
					}
				}
				.buttons {
					margin-top: 10px;
        			z-index: 1;
					.btn {
						width: 100%;
					}
				}
				.footer {
					margin-top: 10px;
        			z-index: 1;
					i {
						margin-right: 5px;
					}
					.not-free {
						color: $gray-500;
					}
				}
				&.wishlist {
					min-height: auto;
					max-height: 80vh;
				}
				&.full {
					padding: 0;
					width: auto;
					max-height: 80vh;
					min-height: 0;
					.image {
						img {
							max-height: 80vh;
						}
					}
				}
				&.external {
					width: auto;
					min-width: 60vw;
					max-height: 80vh;
					min-height: 0;
					overflow: auto;
				}
				#mijoshop {
					.container_oc {
						.breadcrumb {
							display: none;
						}
						> .row {
							margin: 0px;
							padding: 0px;
						}
						#content_oc {
							margin: 0px;
							padding: 0px;
						}
					}

				}
			}
			.close {
				width: 40px;
				height: 40px;
				position: absolute;
				right: -50px;
				text-align: center;
				line-height: 40px;
				i {
					vertical-align: baseline;
				}
			}
		}
		&.empty {
			pointer-events: none;
		}
		.modal-overlay {
			background-color: rgba(0, 0, 0, 0.6);
			position: fixed;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			z-index: 1000;
			&.modal-anim-enter {
				opacity: 0;
				&.modal-anim-enter-active {
					@include animation('bounceIn 250ms linear forwards 0.1s');
				}
			}
			&.modal-anim-leave {
				opacity: 1;
				&.modal-anim-leave-active {
					display: none;
					@include animation('bounceOut 250ms linear forwards 0.1s');
				}
			}
			&.overlay-anim-enter {
				opacity: 0;
				&.overlay-anim-enter-active {
					@include animation('fadeIn 250ms linear forwards 0.1s');
				}
			}
			&.overlay-anim-leave {
				opacity: 1;
				&.overlay-anim-leave-active {
					display: none;
					@include animation('fadeOut 250ms linear forwards 0.1s');
				}
			}
		}
	}
}